// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-both-js": () => import("./../../../src/pages/both.js" /* webpackChunkName: "component---src-pages-both-js" */),
  "component---src-pages-categories-cosmicjs-categories-slug-js": () => import("./../../../src/pages/categories/{CosmicjsCategories.slug}.js" /* webpackChunkName: "component---src-pages-categories-cosmicjs-categories-slug-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-dramatized-js": () => import("./../../../src/pages/dramatized.js" /* webpackChunkName: "component---src-pages-dramatized-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-posts-cosmicjs-posts-slug-js": () => import("./../../../src/pages/posts/{CosmicjsPosts.slug}.js" /* webpackChunkName: "component---src-pages-posts-cosmicjs-posts-slug-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-voiceonly-js": () => import("./../../../src/pages/voiceonly.js" /* webpackChunkName: "component---src-pages-voiceonly-js" */)
}

